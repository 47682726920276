.btn-mod.btn-large {
  height: auto;
  padding: 13px 52px;
  font-size: 15px;
}

.btn-mod.btn-medium {
  height: auto;
  padding: 10px 35px;
  font-size: 13px;
}

.btn-mod.btn-small {
  height: auto;
  padding: 6px 16px;
  font-size: 12px;
}

/* Button Mod to the original Bootstrap Buttons */

.btn-mod.btn-circle {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.btn-mod.btn-border {
  color: white;
  border: 2px solid white;
  background: transparent;
}

.btn-mod,
a.btn-mod {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 13px;
  color: #fff;
  background: rgba(34, 34, 34, 0.9);
  border: 2px solid transparent;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus {
  color: #fff;
  border-color: #323259;
  background: #000;
}

.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  outline: none;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Reveal icon on hover */

a.btn.btn-mod.btn-border.animation-one span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one span:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one:hover span {
  padding-right: 25px;
}

a.btn.btn-mod.btn-border.animation-one:hover span:after {
  opacity: 1;
  right: 0;
}
