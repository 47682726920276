.section-title {
  text-align: center;
  padding-bottom: 60px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  color: white;
}

.section-title h2:before,
.section-title h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: gold;
  display: inline-block;
}

@media (max-width: 800px) {
  .section-title h2 {
    margin-top: 53px;
    font-size: 18px;
}

.section-title h2:before,
.section-title h2:after {
  content: none;
}
}

.section-title h2:before {
  margin: 0 15px 10px 0;
}

.section-title h2:after {
  margin: 0 0 10px 15px;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Page Title & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
}

.page-title .heading {
  padding: 80px 0;
  border-top: 1px solid rgba(var(--default-color-rgb), 0.1);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
  color: var(--secondary-color);
}

.page-title nav {
  background-color: rgba(var(--default-color-rgb), 0.05);
  padding: 20px 0;
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol li+li {
  padding-left: 10px;
}

.page-title nav ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: rgba(var(--default-color-rgb), 0.3);
}

.services .service-item {
  position: relative;
  padding-top: 40px;
}

.services .service-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #6A7695;
}

.services .service-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background: gold;
  border-right: 5px solid gold;
}

.services .service-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-right: 50px;
  line-height: 0;
}

.services .service-item .icon i {
  color: rgba(107, 119, 148, 0.1);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background: rgb(107, 119, 148);
  border-radius: 50px;
  z-index: 1;
  bottom: -15px;
  right: -15px;
  transition: 0.3s;
}

.services .service-item .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.services .service-item .title a {
  color: white;
}

.services .service-item .title a:hover {
  color: white;
}

.services .service-item .description {
  line-height: 24px;
  font-size: 20px;
  text-align: center;
  color:#d4d3d3
}


h4{
  text-align: center;
}

.adjure{
  margin-left: 80px;
}

.adjure2{
  margin-left: 60px;
}

@media(max-width:900px){
  .adjure{
    margin-left:0px;
  }
  
  .adjure2{
    margin-left: 0px;
  }
}