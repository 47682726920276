.w50 {
  width: 30%;
}

.w70 {
  width: 50%;
}

.bglight {
  background-color: aliceblue;
}

.logocolor {
  color: #ffb706;
}

.fright {
  float: right;
}

@media (max-width: 800px) {
  .fright-con {
    margin: auto;
    width: 120px;
    text-align: center;
  }
  .fright {
    float: left;
  }
}

.seo_home_area {
  margin-bottom: 0px;
}
