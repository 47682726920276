.feat {
    display: block;
    width: 620px;
    margin: auto
}

@media(max-width:800px) {
    .feat {
        display: block;
        width: 100%;
        margin: 0
    }
}