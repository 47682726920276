	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/07/2020 07:52
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.html");
  src: url("Flaticond41d.html?#iefix") format("embedded-opentype"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon-2.html#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon-2.html#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-target:before { content: "\f100"; }
.flaticon-lightbulb:before { content: "\f101"; }
.flaticon-mortarboard:before { content: "\f102"; }