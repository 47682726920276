.team .member {
    position: relative;
}


.team .member2 .member-img {
    margin: 0 80px;
    border-radius: 70%;
    overflow: hidden;
    position: relative;
    border: 4px solid gold;
    box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
    max-height: 250px;
}

@media (max-width: 1024px) {
    .team .member2 .member-img {
        margin: 0 60px;
    }
}

.team .member2 .member-img img {
    position: relative;
    z-index: 1;
}

.team .member2 .member-img .social {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding-bottom: 20px;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
}

.team .member2 .member-img .social a {
    transition: 0.3s;
    color: #323259;
    font-size: 20px;
    margin: 0 8px;
}

.team .member2 .member-img .social a:hover {
    color: var(--primary-color);
}

.team .member2 .member-info {
    margin-top: 30px;
}

.team .member2 .member-info h4 {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 18px;
    color: #d4d3d3;
}

.team .member2 .member-info span {
    display: block;
    font-size: 15px;
    color: rgba(var(--default-color-rgb), 0.6);
    margin-bottom: 10px;
}

.team .member2 .member-info p {
    margin-bottom: 0;
    font-size: 14px;
}

.team .member2:hover .member-img .social {
    padding-bottom: 0;
    visibility: visible;
}


.team .member .member-img {
    margin: 0 80px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 4px solid gold;
    box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
    max-height: 220px;
}

@media (max-width: 1024px) {
    .team .member .member-img {
        margin: 0 60px;
    }
}

.team .member .member-img img {
    position: relative;
    z-index: 1;
}

.team .member .member-img .social {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding-bottom: 20px;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
}

.team .member .member-img .social a {
    transition: 0.3s;
    color: #323259;
    font-size: 20px;
    margin: 0 8px;
}

.team .member .member-img .social a:hover {
    color: var(--primary-color);
}

.team .member .member-info {
    margin-top: 30px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 18px;
    color: #d4d3d3;
}

.team .member .member-info span {
    display: block;
    font-size: 15px;
    color: rgba(var(--default-color-rgb), 0.6);
    margin-bottom: 10px;
}

.team .member .member-info p {
    margin-bottom: 0;
    font-size: 14px;
}

.team .member:hover .member-img .social {
    padding-bottom: 0;
    visibility: visible;
}


.advisor_container{
    width: 80%;
    margin: auto;
}


@media (max-width: 800px) {
     h2 {
      margin-top: 53px;
      font-size: 18px;
  }
  
   h2:before,
   h2:after {
    content: none;
  }
  }