.google_form{
    border: 2px solid white;
    border-radius: 40px;
    color: white;
    text-decoration: none;
    padding: 12px;
    display: block;
    width: 100px;
    text-align: center;
    margin: auto;
}

.google_form:hover{
    color: gold;
    border: 2px solid gold;
}